// eslint-disable-next-line import/no-cycle
import {
  USER_LIST_GET_LIST,
  USER_LIST_GET_LIST_SUCCESS,
  USER_LIST_GET_LIST_ERROR,
  USER_LIST_GET_LIST_WITH_FILTER,
  USER_LIST_GET_LIST_WITH_ORDER,
  USER_LIST_GET_LIST_SEARCH,
  USER_LIST_ADD_ITEM,
  USER_LIST_ADD_ITEM_SUCCESS,
  USER_LIST_ADD_ITEM_ERROR,
  USER_LIST_SELECTED_ITEMS_CHANGE,
} from '../actions';

export const getUserList = () => ({
  type: USER_LIST_GET_LIST,
});

export const getUserListSuccess = (items) => ({
  type: USER_LIST_GET_LIST_SUCCESS,
  payload: items,
});

export const getUserListError = (error) => ({
  type: USER_LIST_GET_LIST_ERROR,
  payload: error,
});

export const getUserListWithFilter = (column, value) => ({
  type: USER_LIST_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getUserListWithOrder = (column) => ({
  type: USER_LIST_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getUserListSearch = (keyword) => ({
  type: USER_LIST_GET_LIST_SEARCH,
  payload: keyword,
});

export const addUserItem = (item) => ({
  type: USER_LIST_ADD_ITEM,
  payload: item,
});

export const addUserItemSuccess = (items) => ({
  type: USER_LIST_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addUserItemError = (error) => ({
  type: USER_LIST_ADD_ITEM_ERROR,
  payload: error,
});

export const selectedUserItemsChange = (selectedItems) => ({
  type: USER_LIST_SELECTED_ITEMS_CHANGE,
  payload: selectedItems,
});
