import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import { getDateWithFormat } from 'helpers/Utils';
import { API_URL } from 'constants/defaultValues';

import userListData from 'data/user.list.json';
import { USER_LIST_GET_LIST, USER_LIST_ADD_ITEM } from '../actions';

import {
  getUserListSuccess,
  getUserListError,
  addUserItemSuccess,
  addUserItemError,
} from './actions';

const getUserListRequest = async () => {
  // eslint-disable-next-line no-return-await
  const token = localStorage.getItem('token');
  return axios
    .get(`${API_URL}/admin/UserList/?Page=1&Limit=4`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => error);
};

function* getUserListItems() {
  try {
    const response = yield call(getUserListRequest);
    yield put(getUserListSuccess(response));
  } catch (error) {
    yield put(getUserListError(error));
  }
}

const addUserItemRequest = async (item) => {
  const items = userListData.data;
  // eslint-disable-next-line no-param-reassign
  item.id = items.length + 1;
  // eslint-disable-next-line no-param-reassign
  item.createDate = getDateWithFormat();
  items.splice(0, 0, item);
  // eslint-disable-next-line no-return-await,no-unused-vars
  return await new Promise((success) => {
    setTimeout(() => {
      success(items);
    }, 1000);
  })
    .then((response) => response)
    .catch((error) => error);
};

function* addUserItem({ payload }) {
  try {
    const response = yield call(addUserItemRequest, payload);
    yield put(addUserItemSuccess(response));
  } catch (error) {
    yield put(addUserItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(USER_LIST_GET_LIST, getUserListItems);
}

export function* wathcAddItem() {
  yield takeEvery(USER_LIST_ADD_ITEM, addUserItem);
}

export default function* rootSaga() {
  yield all([fork(watchGetList), fork(wathcAddItem)]);
}
